/**
 * @file Bottom Sheet component
 * @author Alwyn Tan
 */

import { motion } from 'framer-motion'
import React from 'react'
import { ChevronLeft, X } from 'react-feather'
import styled from 'styled-components'
import IconButton from '#/components/atoms/IconButton'
import Text from '#/components/atoms/Text'
import { DarkerGray } from '#/constants/colors'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Container = styled(motion.div)`
  background: ${DarkerGray};
  width: 100%;
  bottom: 0;
  position: absolute;
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 95%;
  max-width: ${({ theme }) => theme.MaxContainerWidth};
`

const HeaderRow = styled.div`
  padding: 20px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const ScrollWrapper = styled.div`
  overflow-y: auto;
  padding: 0 24px;
`

type Props = {
  isFirstSheet: boolean
  onClose: () => void
  hide: boolean
  children: React.ReactNode
  title?: string
  rightHeaderButton?: React.ReactNode
}

const BottomSheet = ({
  isFirstSheet,
  onClose,
  hide,
  children,
  title,
  rightHeaderButton = null,
}: Props) => (
  <Wrapper>
    <Container
      initial={{ y: '95vh' }}
      animate={{ y: hide ? '95vh' : 0 }}
      exit={{ y: '95vh' }}
      transition={{ type: 'spring', duration: 0.5, bounce: 0.1 }}
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <HeaderRow>
        <IconButton onClick={onClose}>
          {isFirstSheet ? <X color="white" /> : <ChevronLeft color="white" />}
        </IconButton>
        <Text type="h5">{title}</Text>
        <IconButton style={{ opacity: rightHeaderButton ? 1 : 0 }}>
          {rightHeaderButton}
        </IconButton>
      </HeaderRow>
      <ScrollWrapper>{children}</ScrollWrapper>
    </Container>
  </Wrapper>
)

export default BottomSheet
