/**
 * @file Icon button
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'

const Container = styled.button`
  background-color: #ffffff1a;
  height: 38px;
  width: 38px;
  border-radius: 20px;
  outline: none;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children?: React.ReactNode
}

const IconButton = ({ children, ...props }: Props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Container {...props}>{children}</Container>
)

export default IconButton
