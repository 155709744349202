import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import styled from 'styled-components'
import Input from '#/components/atoms/Input'
import Text from '#/components/atoms/Text'
import Button from '#/components/molecules/Button'
import Modal from '#/components/templates/Modal'
import useUpdateUser from '#/hooks/query/user/useUpdateUser'

const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

type Props = {
  subtitle: string
}

type FormValues = { name: string }

const NameModal = ({ subtitle }: Props) => {
  const { control, handleSubmit, formState } = useForm<FormValues>({
    mode: 'onChange',
  })
  const updateUserMutation = useUpdateUser()

  const onSubmit: SubmitHandler<FormValues> = ({ name }) => {
    updateUserMutation.mutate({ name })
  }

  return (
    <Modal>
      <Container>
        <div style={{ marginBottom: 16 }}>
          <Text type="h4">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            What's Your Name?
          </Text>
          <Text style={{ opacity: 0.5, paddingTop: 8 }}>{subtitle}</Text>
        </div>
        <Input
          name="name"
          rules={{ required: true }}
          control={control}
          inputProps={{
            autoFocus: true,
            placeholder: 'Enter Your Name',
            autoComplete: 'name',
          }}
        />
        <Button
          disabled={!formState.isValid}
          size="medium"
          style={{ marginTop: 16 }}
          onClick={handleSubmit(onSubmit)}
        >
          Done
        </Button>
      </Container>
    </Modal>
  )
}

export default NameModal
