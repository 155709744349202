/**
 * @file Page Wrapper (for stuff that relies on needing route context) Good article to read on: https://markoskon.com/wrap-root-element-vs-wrap-page-element/
 * @author Alwyn Tan
 */

import { AnimatePresence } from 'framer-motion'
import React from 'react'
import BottomSheetProvider from '#/contexts/bottom-sheet-context'
import GlobalStyle from '#/styles/globalStyle'

type Props = {
  children: React.ReactNode
}

const PageWrapper = ({ children }: Props) => (
  <>
    <GlobalStyle />
    <BottomSheetProvider>
      <AnimatePresence mode="wait">{children}</AnimatePresence>
    </BottomSheetProvider>
  </>
)

export default PageWrapper
