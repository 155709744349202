/**
 * @file Hook to receive current user data from the server
 * @author Alwyn Tan
 */

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { userKeys } from '#/query-key-factory'

type CurrentUserResponseJSON = {
  user: User
}

const getCurrentUser = async () =>
  (await axios.get<CurrentUserResponseJSON>('/user/me')).data

const useCurrentUser = ({ enabled = true } = {}) =>
  useQuery(userKeys.me(), async () => getCurrentUser(), {
    enabled,
    select: data => data?.user,
  })

export default useCurrentUser
