/**
 * @file
 * @author Alwyn Tan
 */

import React, { useEffect, useState } from 'react'
import { ChevronLeft } from 'react-feather'
import { SubmitHandler, useForm } from 'react-hook-form'
import styled from 'styled-components'
import Input from '#/components/atoms/Input'
import Text from '#/components/atoms/Text'
import Modal from '#/components/templates/Modal'
import useLogin from '#/hooks/query/user/useLogin'
import useRequestLogin from '#/hooks/query/user/useRequestLogin'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const TitleContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 32px;
  margin-bottom: 10px;
`

const ResendButton = styled.div<ResendButtonProps>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`

const BackButton = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: #ffffff1a;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  left: 0;
`

type ResendButtonProps = {
  disabled: boolean
}

type OTPModalProps = {
  phoneNumber: string
  onBack: () => void
  onComplete: (otp: string) => void
}

type FormValues = { otp: string }

const OTPModal = ({ phoneNumber, onBack, onComplete }: OTPModalProps) => {
  const { control, handleSubmit } = useForm<FormValues>({ mode: 'onChange' })
  const requestLoginMutation = useRequestLogin()
  const loginMutation = useLogin()
  const [timeLeft, setTimeLeft] = useState(30)

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(v => {
        if (v > 0) return v - 1
        return v
      })
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (requestLoginMutation.isSuccess) {
      setTimeLeft(30)
      const interval = setInterval(() => {
        setTimeLeft(v => {
          if (v > 0) return v - 1
          return v
        })
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }

    return () => {}
  }, [requestLoginMutation.isSuccess])

  const onSubmit: SubmitHandler<FormValues> = async ({ otp }) => {
    try {
      await loginMutation.mutateAsync({ phoneNumber, code: otp })
      onComplete(otp)
    } catch (e) {
      // wat
    }
  }

  const handleOnChange = (v?: string | File) => {
    if (v?.length === 4) handleSubmit(onSubmit)()
  }

  const handleResendClick = () => {
    requestLoginMutation.mutate(phoneNumber.replace(/[() ]/g, ''))
  }

  return (
    <Modal>
      <Container>
        <TitleContainer>
          <BackButton onClick={onBack}>
            <ChevronLeft color="white" />
          </BackButton>
          <Text type="h4">Verify that it is you</Text>
        </TitleContainer>
        <Text style={{ marginBottom: 20 }}>Code sent to {phoneNumber}</Text>
        <Input
          name="otp"
          control={control}
          onChange={handleOnChange}
          inputProps={{
            autoFocus: true,
            placeholder: 'Enter Code Here',
            maxLength: 4,
            type: 'tel',
            autoComplete: 'one-time-code',
          }}
        />
        <ResendButton
          disabled={timeLeft > 0}
          onClick={handleResendClick}
          style={{ marginTop: 16, cursor: 'pointer' }}
        >
          <Text type="button2">
            Resend Code{timeLeft > 0 ? ` (${timeLeft})` : ''}
          </Text>
        </ResendButton>
      </Container>
    </Modal>
  )
}

export default OTPModal
