/**
 * @file
 * @author Alwyn Tan
 */

import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import styled from 'styled-components'
import isMobilePhone from 'validator/lib/isMobilePhone'
import PhoneInput from '#/components/atoms/PhoneInput'
import Text from '#/components/atoms/Text'
import Modal from '#/components/templates/Modal'
import { Purple } from '#/constants/colors'
import useRequestLogin from '#/hooks/query/user/useRequestLogin'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const TermsText = styled.span`
  color: ${Purple};
  cursor: pointer;
`

type Props = {
  onComplete: (phoneNumber: string) => void
  subtitle: string
}

type FormValues = {
  phoneNumber: string
}

const PhoneLoginModal = ({ onComplete, subtitle }: Props) => {
  const { control, handleSubmit } = useForm<FormValues>({ mode: 'onChange' })
  const requestLoginMutation = useRequestLogin()

  const onSubmit: SubmitHandler<FormValues> = async ({ phoneNumber }) => {
    try {
      await requestLoginMutation.mutateAsync(phoneNumber)
      onComplete(phoneNumber)
    } catch (e) {
      // haha
    }
  }

  const handlePhoneChanged = (phoneNumber: string) => {
    if (isMobilePhone(phoneNumber)) handleSubmit(onSubmit)()
  }

  return (
    <Modal>
      <Container>
        <div style={{ marginBottom: 16 }}>
          <Text type="h4">Get Started</Text>
          <Text style={{ opacity: 0.5, paddingTop: 8 }}>{subtitle}</Text>
        </div>
        <PhoneInput
          autoFocus
          name="phoneNumber"
          control={control}
          onChange={handlePhoneChanged}
        />
        <Text type="p2" style={{ marginTop: 16, opacity: 0.5 }}>
          By entering your phone number, you are participating in Disco’s{' '}
          <TermsText>terms & conditions</TermsText> and allow us to send you SMS
          messages. You can opt-out at any time.
        </Text>
      </Container>
    </Modal>
  )
}

export default PhoneLoginModal
