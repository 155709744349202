/**
 * @file Context for authentication related triggers
 * @author Alwyn Tan
 */

import React, { createContext, useContext, useMemo, useRef } from 'react'
import AuthModal from '#/components/organisms/auth/AuthModal'

type AuthModalPresentOptions = {
  phoneLoginSubtitle?: string
  nameSubtitle?: string
  backdropDisabled?: boolean
}

type TAuthContext = {
  authModalRef: React.RefObject<{
    present: (
      cb?: (user: User) => void,
      options?: AuthModalPresentOptions
    ) => void
    dismiss: () => void
  }>
}

type AuthProviderProps = {
  children: React.ReactNode
}

const AuthContext = createContext<TAuthContext>({
  authModalRef: { current: null },
})

const useAuth = () => {
  const context = useContext(AuthContext)

  if (context === undefined)
    throw new Error('useAuth was called outside of its Provider')

  // exposes functions to trigger authentication to show
  return {
    presentAuthModal: (
      cb?: (user: User) => void,
      options?: AuthModalPresentOptions
    ) => context.authModalRef?.current?.present(cb, options),
    dismissAuthModal: () => context.authModalRef?.current?.dismiss(),
  }
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const authModalRef = useRef(null)
  const value = useMemo(() => ({ authModalRef }), [])

  return (
    <AuthContext.Provider value={value}>
      <AuthModal ref={authModalRef} />
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
export { useAuth }
