/**
 * @file All keys for react query
 * @author Alwyn Tan
 */

export const userKeys = {
  all: ['user'],
  me: () => [...userKeys.all, 'me'],
  details: () => [...userKeys.all, 'detail'],
  detail: (username: string) => [...userKeys.details(), username],
}

export const passKeys = {
  all: ['pass'],
  lists: () => [...passKeys.all, 'list'],
  list: ({
    showExpired,
    status,
  }: {
    showExpired?: boolean
    status?: string | string[]
  }) => [...passKeys.lists(), { showExpired, status }],
  details: () => [...passKeys.all, 'detail'],
  detail: (id: string) => [...passKeys.details(), id],
}

export const stlKeys = {
  all: ['stl'],
  lists: () => [...stlKeys.all, 'list'],
  list: (params: any) => [...stlKeys.lists(), params],
}

export const stlInventoryKeys = {
  all: ['stlInventory'],
  lists: () => [...stlInventoryKeys.all, 'list'],
  list: (stlID: string) => [...stlInventoryKeys.lists(), stlID],
  details: () => [...stlInventoryKeys.all, 'detail'],
  detail: (id: string) => [...stlInventoryKeys.details(), id],
}

export const stripeKeys = {
  all: ['stripe'],
  paymentMethods: () => [...stripeKeys.all, 'payment-methods'],
}
